import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import Video from "../Video";
import Title from "../Commons/Title";
import Paragraph from "../Commons/Paragraph";
import Button from "../Commons/Button";
import VideoImageMediaPoster from "../../images/hero/video-image-poster-media.png";
// import "lazysizes"
// import "lazysizes/plugins/attrchange/ls.attrchange"
// import "lazysizes/plugins/bgset/ls.bgset"

const DescriptionWrapper = styled.div`
  margin-top: 24px;
  margin-left: 0px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 36px;
    margin-left: 32px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 42px;
    margin-left: 0px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 72px;
  margin-bottom: 55px;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #8b8682;
    margin-top: 68px;
  }
`;

const SubTitleWrapper = styled.div`
  margin-top: 24px;
`;

type IeImage = {
  ie_image?: string
}

const ImageBox = styled.div<IeImage>`
  width: 100%;
  height: 100%;
  position: relative;
  img {
    width: 100%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${(props) => props.ie_image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
`;

type VideoHiddenBoxType = {
  isVideo: string | boolean;
};

const VideoMobileHiddenBox = styled.div<VideoHiddenBoxType>`
  width: 100%;
  display: ${(props) => (props.isVideo ? "none" : "block")};
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: block;
  }
`;

const VideoWrapper = styled.div`
  margin-top: 38px;
  margin-bottom: 50px;
  padding: 0px 24px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 96px;
    margin-bottom: 96px;
    padding: 0px;
  }
`;

const VideoTextWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 0px 24px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: none;
  }
`;

const VideoButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  button {
    margin-bottom: 32px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 0px 24px;
    margin-top: 0px;
    display: block;
    text-align: left;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: none;
  }
`;

type PROPS_TYPE = {
  clipPath_lg?: string;
  clipPath_md?: string;
  clipPath_sm?: string;
  bg_color: string;
  ie_sm?: string;
  ie_md?: string;
  ie_lg?: string;
  ie_xl?: string;
};

const Section = styled.section<PROPS_TYPE>`
  padding: 20px 0 98px 0;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.bg_color};
  position: relative;
  clip-path: ${(props) => props.clipPath_sm};

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 50px 0 172px 0;
    clip-path: ${(props) => props.clipPath_md};
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 74px 0 64px 0;
    clip-path: ${(props) => props.clipPath_lg};
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    background-image: url(${(props) => props.ie_sm});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    background-image: url(${(props) => props.ie_md});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    background-image: url(${(props) => props.ie_lg});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-image: url(${(props) => props.ie_xl});
  }
`;

type HeroType = {
  title: string;
  subTitle?: string;
  description: string;
  image?: string;
  video?: string;
  videoTitle?: string;
  iframeLink?: string;
  text_color: string;
  cta_text?: string;
  cta_subtext?: string;
  cta_type?: "secondary" | "primary" | "tertiary";
  clipPath_lg?: string;
  clipPath_md?: string;
  clipPath_sm?: string;
  bg_color: string;
  desktopBG?: string;
  laptopBG?: string;
  tabletBG?: string;
  mobileBG?: string;
};

export default function HeroType2(props: HeroType) {
  const { title, subTitle, description, image, video, videoTitle, iframeLink, text_color, cta_text, cta_subtext, cta_type } = props;

  return (
    <Section
      clipPath_lg={props.clipPath_lg}
      clipPath_md={props.clipPath_md}
      clipPath_sm={props.clipPath_sm}
      className="lazyload"
      ie_sm={props.mobileBG}
      ie_md={props.tabletBG}
      ie_lg={props.laptopBG}
      ie_xl={props.desktopBG}
      data-bgset={` ${props.desktopBG} [(min-width: 1440px)] | ${props.laptopBG} [(min-width: 768px)] | ${props.tabletBG} [(min-width: 320px)] | ${props.mobileBG}`}
      bg_color={props.bg_color}
    >
      <Container>
        <Row justify={video && !!video.length ? "center" : "flex-start"}>
          <Col xs={2} sm={2} md={video && !!video.length ? 6 : 5} lg={6} xl={6}>
            <Row style={{ alignContent: "flex-start" }}>
              <Col xs={2} sm={2} md={7} lg={12} xl={12}>
                <Title
                  level={1}
                  uppercase
                  variable="primary"
                  color="white"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Col>
              <Col xs={2} sm={2} md={6} lg={10} xl={12}>
                <SubTitleWrapper>
                  <Title level={4} light uppercase variable="secondary" color="mushroom">
                    {subTitle}
                  </Title>
                </SubTitleWrapper>
              </Col>
              <VideoMobileHiddenBox isVideo={video && !!video.length ? video : false}>
                <Col xs={2} sm={2} md={7} lg={10} xl={10} offset={{ xs: 0, sm: 0, md: 0, lg: 2, xl: 2 }}>
                  <DescriptionWrapper>
                    <Paragraph 
                      color={text_color} 
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </DescriptionWrapper>
                  {cta_text && !!cta_text.length && (
                    <ButtonWrapper>
                      {/* <Button size="large" as="a" type={cta_type ? cta_type : "primary"}>
                        {cta_text}
                      </Button> */}
                      {cta_subtext && !!cta_subtext.length && <p>{cta_subtext}</p>}
                    </ButtonWrapper>
                  )}
                </Col>
              </VideoMobileHiddenBox>
            </Row>
          </Col>
          {video && !!video.length ? (
            <Col xs={2} sm={2} md={8} lg={5} xl={5} offset={{ xs: 0, sm: 0, md: 0, lg: 1, xl: 1 }}>
              <Row justify="center">
                <Col xs={2} sm={2} md={6} lg={12} xl={12}>
                  <VideoWrapper>
                    <Video src={video} iframeLink={iframeLink} title={videoTitle}/>
                  </VideoWrapper>
                </Col>
                <Col xs={2} sm={2} md={4} lg={12} xl={12}>
                  <VideoTextWrapper>
                    <Paragraph 
                      color={text_color} 
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </VideoTextWrapper>
                </Col>
                <Col xs={2} sm={2} md={4} lg={12} xl={12} align="flex-start">
                  <VideoButtonWrapper>
                    {/* <Button size="large" type={cta_type ?? "primary"}>
                      {cta_text}
                    </Button> */}
                    {cta_subtext && !!cta_subtext.length && <Paragraph color={text_color}>{cta_subtext}</Paragraph>}
                  </VideoButtonWrapper>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col xs={2} sm={2} md={3} lg={6} xl={6} align="flex-end">
              <ImageBox ie_image={image}>
                <img className="lazyload noselect" data-src={image} />
              </ImageBox>
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  );
}
