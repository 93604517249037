import React from "react"
import { graphql } from "gatsby";
import HeroType2 from "../HeroType2"

import HeroDesktop from "../../images/about/abouthero-lg.jpg"
import Herolaptop from "../../images/about/abouthero-md.jpg"
import HeroTablet from "../../images/about/abouthero-sm.jpg"
import HeroMobile from "../../images/about/abouthero-xs.jpg"
import HeroBraum from "../../images/about/about-heim.png"

export const fragment = graphql`
  fragment AboutMarqueeFragment on Contentstack_forge_about_page {
    marquee {
      headline
      flavorCopy: flavor_copy
      descriptionCopy: description_copy
      legalCopy: legal_copy
    }
  }
`

export type HeroAboutData = {
  headline: string
  flavorCopy: string
  descriptionCopy: string
  legalCopy: string
}

interface Props {
  data: HeroAboutData
}

const HeroAbout = (props: Props) => {
  const { headline, flavorCopy, descriptionCopy, legalCopy } = props.data

  return (
    <HeroType2
      title={headline}
      subTitle={flavorCopy}
      description={descriptionCopy}
      text_color="mushroom"
      image={HeroBraum}
      mobileBG={HeroMobile}
      tabletBG={HeroTablet}
      laptopBG={Herolaptop}
      desktopBG={HeroDesktop}
      clipPath_lg="polygon(
        0% 0%,
        100% 0%,
        100% calc(100% - 124px),
        42.02729% calc(100% - 8px),
        43% calc(100% - 92px),
        0% 100%
      )"
      clipPath_md="polygon(
        0% 0%, 
        100% 0%, 
        100% calc(100% - 72px), 
        32.57% calc(100% - 8px), 
        34.63% calc(100% - 88px), 
        0% calc(100% - 51px)
      )"
      clipPath_sm="polygon(
        0% 0%, 
        100% 0%, 
        100% calc(100% - 40px), 
        16.56% calc(100% - 0px), 
        17.5% calc(100% - 42px), 
        0% calc(100% - 30px)
      )"
      bg_color="#0F244F"
    />
  )
}

export default HeroAbout
