import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-awesome-styled-grid";
import Paragraph from "../Commons/Paragraph";
import Title from "../Commons/Title";
import { MobileView, DesktopView } from "../Styled";
import FaqMobile from "../../images/faq/faq-mobile-up.jpg";
import FaqTabletTop from "../../images/faq/faq-tablet-top.jpg";
import FaqTabletBottom from "../../images/faq/faq-tablet-bottom.jpg";
import FaqLaptop from "../../images/faq/faq-laptop.jpg";
import FaqDesktop from "../../images/faq/faq-desktop.jpg";
import * as S from "./styles";

const mobileBG = FaqMobile;
const tabletTopBG = FaqTabletTop;
const tabletBottomBG = FaqTabletBottom;
const laptopBG = FaqLaptop;
const desktopBG = FaqDesktop;
const bg_color = "#1F2021";

export const fragment = graphql`
  fragment AboutFaqFragment on Contentstack_forge_about_page {
    faq: group {
      headline
      developString: develop_string
      generalString: general_string
      faqs {
        title
        answer
        select
      }
    }
  }
`;

export type FaqData = {
  headline: string;
  developString: string;
  generalString: string;
  faqs: {
    title: string;
    answer: string;
    select: string;
  }[];
};

type PropsType = {
  data: FaqData;
};

export default function Faq(props: PropsType) {
  const { headline, faqs } = props.data;

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);

  // Categories
  const categories = [...new Set(faqs.map((faq) => faq.select))];
  const tabs = categories.map((category) => {
    return {
      title: category,
      items: faqs
        .filter((faq) => faq.select == category)
        .map((faq) => ({
          title: faq.title,
          data: {
            title: faq.title,
            description: faq.answer,
          },
        })),
    };
  });

  useEffect(() => {
    setSelectedItem(0);
  }, [selectedTab]);

  return (
    <S.Section
      mobileBG={mobileBG}
      tabletTop={tabletTopBG}
      tabletBottom={tabletBottomBG}
      laptopBG={laptopBG}
      desktopBG={desktopBG}
      bg_color={bg_color}
    >
      <S.Content>
        <Container>
          <Row justify={{ md: "center", lg: "flex-start" }}>
            <Col xs={2} sm={2} md={6} lg={5} xl={5}>
              <Row>
                <Col sm={10}>
                  <Title level={2} color="cream" uppercase>
                    {headline}
                  </Title>
                  <S.List>
                    {tabs[selectedTab].items.map((item: { title: string }, index: number) => {
                      return (
                        <span key={index}>
                          <S.ListItem
                            key={index}
                            className={index === selectedItem ? "active" : ""}
                            onClick={() => setSelectedItem(index)}
                          >
                            <span>{item.title}</span>
                            <S.DownArrow active={index === selectedItem}>
                              <svg
                                width="11"
                                height="9"
                                viewBox="0 0 11 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5.5 9L-3.23854e-07 -2.47671e-07L11 9.53674e-07L5.5 9Z" fill="white" />
                              </svg>
                            </S.DownArrow>
                          </S.ListItem>
                          <MobileView>
                            {index === selectedItem && (
                              <S.DetailWrapper>
                                <div>
                                  <S.TitleWrapper>
                                    <Title level={3} color="smoke" uppercase>
                                      {tabs[selectedTab].items[selectedItem].data.title}
                                    </Title>
                                  </S.TitleWrapper>
                                  <S.ParagraphWrapper>
                                    <Paragraph color="base">
                                      {tabs[selectedTab].items[selectedItem].data.description}
                                    </Paragraph>
                                  </S.ParagraphWrapper>
                                </div>
                              </S.DetailWrapper>
                            )}
                          </MobileView>
                        </span>
                      );
                    })}
                  </S.List>
                </Col>
              </Row>
            </Col>
            <Col
              xs={2}
              sm={2}
              md={6}
              lg={5}
              xl={5}
              offset={{
                xs: 0,
                sm: 0,
                md: 0,
                lg: 2,
                xl: 2,
              }}
            >
              <DesktopView>
                <S.DesktopBgWrapper>
                  <S.DetailWrapper>
                    <div>
                      <S.TitleWrapper>
                        <Title level={3} color="smoke" uppercase>
                          {tabs[selectedTab].items[selectedItem].data.title}
                        </Title>
                      </S.TitleWrapper>
                      <S.ParagraphWrapper>
                        <Paragraph color="base">{tabs[selectedTab].items[selectedItem].data.description}</Paragraph>
                      </S.ParagraphWrapper>
                    </div>
                  </S.DetailWrapper>
                </S.DesktopBgWrapper>
              </DesktopView>
            </Col>
          </Row>
        </Container>
      </S.Content>
    </S.Section>
  );
}
