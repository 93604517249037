import React, { useEffect } from "react"
import { graphql } from "gatsby";
import styled from "styled-components"
import { Container, Row, Col, IOffsetOptions } from "react-awesome-styled-grid"

import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"
// import "lazysizes"
// import "lazysizes/plugins/attrchange/ls.attrchange"
// import "lazysizes/plugins/bgset/ls.bgset"

const Wrapper = styled.div`
  display: block;
`

const DescriptionWrapper = styled.div`
  margin-top: 24px;
`

type ImageWrapperType = {
  reverse: boolean
  ie_image?: string
}

const ImageWrapper = styled.div<ImageWrapperType>`
  position: relative;
  margin: ${props => (props.reverse ? "24px 0px 0px 0px" : "0px 0px 24px 0px")};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 0;
  }
  img {
    width: 100%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${(props) => props.ie_image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
`

type PROPS_TYPE = {
  order?: number
  clipPath_lg?: any
  clipPath_md?: any
  clipPath_sm?: any
  mobileBG?: string
  desktopBG?: string
  laptopBG?: string
  tabletBG?: string
  leftLightningImage?: any
  bg_color?: string
  reverse?: boolean
  ie_sm?: string
  ie_md?: string
  ie_lg?: string
  ie_xl?: string
}

const Section = styled.section<PROPS_TYPE>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.bg_color};
  position: relative;
  z-index: ${props => (props.order === 1 ? 4 : 3)};
  clip-path: ${props => (props.clipPath_sm ? props.clipPath_sm : "none")};
  padding: ${props =>
    props.order === 1
      ? "28px 0 64px 0px"
      : props.order === 2
      ? "104px 0 78px 0px"
      : "14px 0 98px 0px"};
  margin-top: ${props => props.order === 2 && "-43px"};

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    clip-path: ${props => (props.clipPath_md ? props.clipPath_md : "none")};
    padding: ${props =>
      props.order === 1
        ? "64px 0 62px 0px"
        : props.order === 2
        ? "100px 0 86px 0px"
        : "28px 0 120px 0px"};
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    clip-path: ${props => (props.clipPath_lg ? props.clipPath_lg : "none")};
    padding: ${props =>
      props.order === 1
        ? "46px 0 0px 0px"
        : props.order === 2
        ? "200px 0 320px 0px"
        : "72px 0 200px 0px"};

    &::before {
      content: url("${props => props?.leftLightningImage}");
      position: absolute;
      bottom: -330px;
      left: -372px;
      z-index: -1;
      pointer-events: none;
      // transform: scale(0.1);
    }

    &::after {
      content: url("${props => props?.leftLightningImage}");
      position: absolute;
      bottom: -530px;
      right: -400px;
      transform: scale(0.1);
      transform: rotate(180deg);
      z-index: -1;
      pointer-events: none;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    min-height: 920px;

    &::before {
      bottom: -330px;
      left: -172px;
      pointer-events: none;
    }

    &::after {
      bottom: -330px;
      right: -300px;
      pointer-events: none;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    min-height: 550px;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    background-image: url(${(props) => props.ie_sm});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    background-image: url(${(props) => props.ie_md});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    background-image: url(${(props) => props.ie_lg});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-image: url(${(props) => props.ie_xl});
  }

`

type BirdsWrapperType = {
  src?: string
}

const BirdsWrapper = styled.div<BirdsWrapperType>`
  span {
    display: none;

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
      //pointer-events: none;
      display: block;
      position: absolute;
      top: 50px;
      right: 138px;
      background-image: url("${props => props.src}");
      background-repeat: no-repeat;
      width: 61px;
      height: 82px;
    }

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
      display: none;
    }
  }
`

export const fragment = graphql`
  fragment AboutRiverFlowFragment on Contentstack_forge_about_page {
    riverFlow: river_flow_components {
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
    }
  }
`

export type TextWithImageData = {
  headline: string
  bodyCopy: string
  ctaLink: {
    title: string
    href: string
  }
}

type TextWithImageType = {
  data: TextWithImageData
  order?: number
  clipPath_lg?: any
  clipPath_md?: any
  clipPath_sm?: any
  mobileBG?: string
  desktopBG?: string
  laptopBG?: string
  tabletBG?: string
  leftLightningImage?: any
  bg_color?: string
  image?: string
  text_animation_class?: string
  image_animation_class?: string
  reverse?: boolean
  title?: string
  description?: string
  head_color: string
  paragraph_color?: string
  query?: any
  offset?: IOffsetOptions
  imageOffset?: IOffsetOptions
  imageQuery?: {}
  birds?: string
}

export default function TextWithImage(props: TextWithImageType) {
  const {
    text_animation_class,
    image_animation_class,
    title,
    description,
    image,
    reverse = false,
    head_color,
    paragraph_color = "base",
  } = props

  const {
    headline,
    bodyCopy,
    ctaLink,
  } = props.data

  return (
    <Section
      order={props.order}
      reverse={reverse}
      mobileBG={props.mobileBG}
      tabletBG={props.tabletBG}
      laptopBG={props.laptopBG}
      desktopBG={props.desktopBG}
      leftLightningImage={props.leftLightningImage}
      clipPath_lg={props.clipPath_lg}
      clipPath_md={props.clipPath_md}
      clipPath_sm={props.clipPath_sm}
      className="lazyload"
      ie_sm={props.mobileBG}
      ie_md={props.tabletBG}
      ie_lg={props.laptopBG}
      ie_xl={props.desktopBG}
      data-bgset={` ${props.desktopBG} [(min-width: 1440px)] | ${props.laptopBG} [(min-width: 768px)] | ${props.tabletBG} [(min-width: 320px)] | ${props.mobileBG}`}
      bg_color={props.bg_color}
    >
      <Container>
        <Row align="center" justify="space-between">
          <Col
            order={reverse ? 1 : 2}
            {...props.query}
            offset={{ ...props.offset }}
          >
            <Wrapper className={text_animation_class}>
              <Title level={2} uppercase color={head_color}>
                {headline}
              </Title>
              <DescriptionWrapper>
                <Paragraph color={paragraph_color}>{bodyCopy}</Paragraph>
              </DescriptionWrapper>
            </Wrapper>
          </Col>
          <Col
            order={reverse ? 2 : 1}
            {...props.imageQuery}
            offset={{ ...props.imageOffset }}
          >
            <ImageWrapper reverse={reverse} className={image_animation_class} ie_image={image}>
              <img className="lazyload noselect" data-src={image}/>
            </ImageWrapper>
          </Col>
        </Row>
      </Container>
      <BirdsWrapper src={props.birds}>
        <span />
      </BirdsWrapper>
    </Section>
  )
}
