// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
// import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout";
import TextWithImage, { TextWithImageData } from "../components/TextWithImage";
import HeroAbout, { HeroAboutData } from "../components/HeroAbout";
import Faq, { FaqData } from "../components/Faq";
import Seo, { SeoData } from "../components/Seo/Seo";

// images
import Braum from "../images/about/braum.png";
import Ekko from "../images/about/ekko.png";
import LightningImage from "../images/lightningImages.png";
import Birds from "../images/birds.png";

/*
import HeroDesktop from "../images/about/abouthero-lg.jpg"
import Herolaptop from "../images/about/abouthero-md.jpg"
import HeroTablet from "../images/about/abouthero-sm.jpg"
import HeroMobile from "../images/about/abouthero-xs.jpg"
import HeroBraum from "../images/about/about-heim.png"
*/

import EkkoBgDesktop from "../images/about/about-ekko-bg-desktop.jpg";
import EkkoBgLaptop from "../images/about/about-ekko-bg-laptop.jpg";
import EkkoBgTablet from "../images/about/about-ekko-bg-tablet.jpg";
import EkkoBgMobile from "../images/about/about-ekko-bg-mobile.jpg";

/*
import FaqMobile from "../images/faq/faq-mobile-up.png"
import FaqTabletTop from "../images/faq/faq-tablet-top.png"
import FaqTabletBottom from "../images/faq/faq-tablet-bottom.png"
import FaqLaptop from "../images/faq/faq-laptop.png"
import FaqDesktop from "../images/faq/faq-desktop.png"
*/

import { useSiteMetadata } from "../hooks/use-site-metadata";

interface PageData {
  about: {
    aboutSeo: SeoData;
    marquee: HeroAboutData;
    riverFlow: TextWithImageData[];
    faq: FaqData;
  };
}

interface Props extends PageProps<PageData> {}

const AboutPage: React.FC<Props> = ({ data }) => {
  // const { t } = useTranslation()

  useEffect(() => {
    if (window !== undefined) {
      require("lazysizes/plugins/attrchange/ls.attrchange.js");
      require("lazysizes/plugins/bgset/ls.bgset");
      require("lazysizes");
    }
  }, []);

  const { aboutSeo, marquee, riverFlow, faq } = data?.about;

  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();

  const aboutUrl = `https://${siteDomain}/${language}/about/`;

  return (
    <Layout>
      <Seo data={aboutSeo} url={aboutUrl} locale={language} />

      {/*
      <HeroType2
        title="about <br /> riot forge"
        subTitle="Lorem ipsum dolor sit amet, americas  consectetur."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eget tortor malesuada, faucibus justo et, mattis."
        text_color="mushroom"
        image={HeroBraum}
        mobileBG={HeroMobile}
        tabletBG={HeroTablet}
        laptopBG={Herolaptop}
        desktopBG={HeroDesktop}
        clipPath_lg="polygon(
          0% 0%,
          100% 0%,
          100% calc(100% - 124px),
          42.02729% calc(100% - 8px),
          43% calc(100% - 92px),
          0% 100%
        )"
        clipPath_md="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100% - 72px), 
          32.57% calc(100% - 8px), 
          34.63% calc(100% - 88px), 
          0% calc(100% - 51px)
        )"
        clipPath_sm="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100% - 40px), 
          16.56% calc(100% - 0px), 
          17.5% calc(100% - 42px), 
          0% calc(100% - 30px)
        )"
        bg_color="#0F244F"
      />
      */}
      <HeroAbout data={marquee} />

      <TextWithImage
        data={riverFlow[0]}
        head_color=""
        order={1}
        query={{
          xs: 2,
          sm: 2,
          md: 4,
          lg: 6,
          xl: 6,
        }}
        offset={{
          xs: 0,
          sm: 0,
          md: 0,
          lg: 0,
          xl: 0,
        }}
        imageQuery={{
          md: 4,
          lg: 4,
          xl: 4,
        }}
        imageOffset={{
          xs: 0,
          sm: 0,
          md: 0,
          lg: 1,
          xl: 1,
        }}
        text_animation_class="animation-up"
        image_animation_class="animation-up"
        title="What We Build"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vitae pellentesque lectus. Fusce gravida augue ornare, consequat
              sem quis, vestibulum urna. Sed egestas accumsan viverra. Lamitos
              augue ornare, consequat sem quis, vestibulum urna. Sed egestas
              accumsan viverra."
        image={Braum}
        leftLightningImage={LightningImage}
        birds={Birds}
        clipPath_sm="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100% - 42px), 
          16.56% calc(100% - 0px), 
          17.5% calc(100% - 38px), 
          0% calc(100% - 28px)
        )"
        bg_color="#fff"
      />

      <TextWithImage
        data={riverFlow[1]}
        order={2}
        reverse
        query={{
          xs: 2,
          sm: 2,
          md: 5,
          lg: 5,
          xl: 5,
        }}
        offset={{
          xs: 0,
          sm: 0,
          md: 2,
          lg: 0,
          xl: 0,
        }}
        imageQuery={{
          md: 4,
          lg: 6,
          xl: 6,
        }}
        imageOffset={{
          xs: 0,
          sm: 0,
          md: 2,
          lg: 0,
          xl: 0,
        }}
        text_animation_class="animation-up"
        image_animation_class="animation-up"
        title="our passion"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              vitae pellentesque lectus. Fusce gravida augue ornare, consequat
              sem quis, vestibulum urna. Sed egestas accumsan viverra. Lamitos
              augue ornare, consequat sem quis, vestibulum urna. Sed egestas
              accumsan viverra."
        head_color="cream"
        paragraph_color="mushroom"
        image={Ekko}
        mobileBG={EkkoBgMobile}
        tabletBG={EkkoBgTablet}
        laptopBG={EkkoBgLaptop}
        desktopBG={EkkoBgDesktop}
        clipPath_lg="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100% - 123px), 
          70.69% calc(100% - 58px), 
          71.66% calc(100% - 148px), 
          0% calc(100%)
        )"
        clipPath_md="polygon(
          0% 0%,
          100% 0%,
          100% calc(100% - 119px),
          15.62% calc(100% - 0px),
          17.57% calc(100% - 86px),
          0% calc(100% - 72px)
        )"
        clipPath_sm="polygon(
          0% 0%, 
          100% 0%, 
          100% calc(100% - 48px),
          0% calc(100%)
        )"
        bg_color="#1D2242"
      />

      <Faq data={faq} />
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    about: contentstackForgeAboutPage(locale: { eq: $language }) {
      ...AboutSeoFragment
      ...AboutMarqueeFragment
      ...AboutRiverFlowFragment
      ...AboutFaqFragment
    }
    ...TranslationQueryFragment
  }
`;
