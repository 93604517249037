import styled, { css } from "styled-components";

type STYLE_PROPS = {
    bg_color: string;
    mobileBG: string;
    tabletTop: string;
    tabletBottom: string;
    laptopBG: string;
    desktopBG: string;
  };

export const Section = styled.section<STYLE_PROPS>`
  padding: 94px 0 210px 0;
  margin-top: -43px;
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.bg_color};
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
    background-position: top center;
    background-size: cover;
    background-image: url("${(props) => props.tabletTop}");
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: bottom center;
    background-size: cover;
    background-image: url("${(props) => props.tabletBottom}");
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    position: relative;
    padding: 160px 0 104px;
    margin-top: -98px;
    &:before {
      background-image: url("${(props) => props.tabletTop}");
    }
    &:after {
      background-image: url("${(props) => props.tabletBottom}");
    }
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    &:before {
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 51%;
      background-image: none;
      background: whitesmoke;
      z-index: 2;
    }
    &:after {
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 49%;
      z-index: 1;
      background-position: top left;
      background-image: url("${(props) => props.laptopBG}");
    }
    padding: 240px 0 40px;
    margin-top: -149px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    &:after {
      background-image: url("${(props) => props.desktopBG}");
    }
  }
`;

export const Content = styled.div`
min-height: 600px;
position: relative;
z-index: 2;
`;

export const TitleWrapper = styled.div`
  margin-top: 24px;
`;

export const ParagraphWrapper = styled.div`
margin-top: 48px;
@media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
  margin-top: 24px;
}
`;

export const DetailWrapper = styled.div`
position: relative;
padding: 25px;
min-height: 350px;
margin-bottom: 10px;
&:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0px;
  background: white;
  z-index: 1;
}
div {
  position: relative;
  z-index: 2;
}
@media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
  margin-bottom: 0px;
  min-height: 650px;
  padding: 96px 0px 110px 0px;
  &:before {
    content: none;
    padding: 0px;
  }
}
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 65px;
  }
`;

export const DesktopBgWrapper = styled.ul`
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 51%;
      background: whitesmoke;
      z-index: 2;
    }
  }
`;

export type DownArrowType = {
    active: boolean;
  };

export const DownArrow = styled.div<DownArrowType>`
right: 0;
position: absolute;
z-index: 2;
svg {
  fill: white;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.active ? "rotate(-90deg)" : "rotate(0deg)")};
}
@media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
  svg {
    display: ${(props) => (props.active ? "block" : "none")};
  }
}
`;

export const ListItem = styled.li`
position: relative;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 24px;
display: flex;
flex-direction: column;
align-items: flex-start;
letter-spacing: 0.02em;
color: #ffffff;
padding: 20px 0;
cursor: pointer;
&.active {
  background-color: ${(props) => props.theme.variables.primary.black};
  span {
    position: relative;
    z-index: 2;
    width: 95%;
  }
  &:before {
    content: "";
    position: absolute;
    background: #111111;
    left: 0;
    width: 200vw;
    transform: translate(-20%, 0);
    height: 100%;
    bottom: 0;
    top: 0;
    background: ${(props) => props.theme.variables.primary.black};
  }
}
@media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
  align-items: center;
  padding: 20px 0;
  flex-direction: row;
  &.active {
    &:before {
      width: 100vw;
      transform: translate(-50%, 0);
      right: -25vw;
      z-index: 1;
    }
  }
}
`;