import styled, { css } from "styled-components"

export const DFlex = styled.div(
  () => css`
    display: flex;
  `
)

export const DFlexCenter = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

export const PRelative = styled.div(
  () => css`
    position: relative;
  `
)

export const MobileView = styled.div(
  () => css`
    display: block;
    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
      display: none;
    }
  `
)

export const DesktopView = styled.div(
  () => css`
    display: none;
    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
      display: block;
    }
  `
)
